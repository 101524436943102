<section class="funfacts-area ptb-100 bg-image">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="section-title">  
                    <span [innerHtml]="'services.quote' | translate"></span>
                    <h2 [innerHtml]="'services.title' | translate"></h2>
                    <p [innerHtml]="'services.paragraph_text' | translate"></p>                                     
                    <a routerLink="/services" class="btn btn-primary" [innerText]="'services.button_more' | translate"></a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="counter-wrap" *ngFor="let service of services;">

                    <div class="single-counter">
                        <!-- <h2 class="odometer" data-count="1024">00</h2> -->
                        <h2>
                            <div [id]="'icon-services'+service" class="icon" [style]="'transform:rotate('+transformRotes[service - 1]+'deg);'">
                                <i class="{{'services.details.'+service+'.icon' | translate}}"></i>
                            </div>
                        </h2>  
                        <a [href]="'services/'+service" style="pointer-events: none; cursor: default;">
                            <h3 [innerHtml]="'services.details.'+service+'.title' | translate"></h3>
                        </a>
                        <p [innerHtml]="'services.details.'+service+'.short_description' | translate" style="margin-bottom: 10px;"></p>

                    </div>                  
                
                </div>
            </div>
        </div>
    </div>
</section>