<section [class]="'ptb-100 bg-image ' + (lightBackground ? 'faq-area' : 'why-we-different')">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 why-we-different-img" *ngIf="imageLeft">
                <div class="tabs_item_img">
                    <img [src]="imagePath" [alt]="imageTitle" [title]="imageTitle">
                </div>
            </div>
                        
            <div class="col-lg-6 col-md-12 why-we-different-content">
                <div class="tabs_item_content" [innerHtml]="description">                    
                </div>
            </div>
            
            <div class="col-lg-6 col-md-12 why-we-different-img" *ngIf="!imageLeft">
                <div class="tabs_item_img">
                    <img [src]="imagePath" [alt]="imageTitle" [title]="imageTitle">
                </div>
            </div>
        </div>
    </div>
</section>