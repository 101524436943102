<section [class]="'page-title-area' + (('portfolio.image' | translate).length > 0 ? ' '+('portfolio.image' | translate) :'item-bg1')">
    <div class="container">
        <div class="section-title section-title-color">
            <span *ngIf="('portfolio.quote' | translate).length > 1" [innerHtml]="'portfolio.quote' | translate"></span>
            <span *ngIf="('portfolio.quote' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'portfolio.quote'}}</span>

            <h2 *ngIf="('portfolio.details.'+portfolioId+'.title' | translate).length > 1" [innerHtml]="'portfolio.details.'+portfolioId+'.title' | translate"></h2>
            <h2 *ngIf="('portfolio.details.'+portfolioId+'.title' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'portfolio.details.'+portfolioId+'.title'}}</h2>

            <p *ngIf="('portfolio.details.'+portfolioId+'.short_description' | translate).length > 1" [innerHtml]="'portfolio.details.'+portfolioId+'.short_description' | translate" [style]="('portfolio.details.'+portfolioId+'.short_description' | translate).length < 66 ? 'margin-bottom: 60px': ''"></p>
            <p *ngIf="('portfolio.details.'+portfolioId+'.short_description' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'portfolio.details.'+portfolioId+'.short_description'}}</p>                                    
                        
        </div>
    </div>
</section>

<section class="contact-area ptb-100 bg-image">
    <div class="container">
        <div class="row">
            <div class="blog-details">
                <div class="post-image">
                    <img [src]="'assets/img/work-img'+portfolioId+'.jpg'" class="img-center" alt="image">
                </div>
                <div [innerHtml]="'portfolio.details.'+portfolioId+'.long_description' | translate">
                </div>                
            </div>
        </div>
    </div>
</section>

<app-cta-two id="contact"></app-cta-two>