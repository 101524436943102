import { Component, OnInit } from '@angular/core';
import { TranslateHelper } from 'src/app/shared/translate-helper';

@Component({
  selector: 'app-about-us-page',
  templateUrl: './about-us-page.component.html',
  styleUrls: ['./about-us-page.component.scss']
})
export class AboutUsPageComponent implements OnInit {

  details: any[] = [];
  imageLeft: boolean = false;
  lightBackground: boolean = true;

  groupStructures: any[] = [];

  constructor(private translateHelper: TranslateHelper) { }

  ngOnInit(): void {
    this.details = this.translateHelper.getListOfNo('about_us.details.', '.description');

    let grStr = this.translateHelper.getListOfNo('about_us.groupStructures.', '.name') as any[];

    grStr.forEach((gs) => {
      // console.log("this.gs: ", gs);
      // console.log("this.index: ", index);
      let items = this.translateHelper.getListOfNo(`about_us.groupStructures.${gs}.items.`, '.name') ?? [];
      if (items && items.length > 0) {
        let itemsVal: any[] = [];
        items.forEach((item) => {
          // console.log(`about_us.groupStructures.${gs}.items.${item}.subitems.`);

          let subitems = this.translateHelper.getListOfNo(`about_us.groupStructures.${gs}.items.${item}.subitems.`, '.name') ?? [];
          let isDown = false;
          if (gs == 4 && item == 3) {
            isDown = true;
          }

          if (subitems && subitems.length > 0) {
            // this.groupStructures.push({ index: gs, items: { index: item, subitems: subitems } });
            itemsVal.push({ index: item, isDown: isDown, subitems: subitems });
          } else {
            // this.groupStructures.push({ index: gs, items: { index: item, subitems: [] } });
            itemsVal.push({ index: item, isDown: isDown, subitems: [] });
          }
        });

        this.groupStructures.push({index: gs, items: itemsVal});
      }
    });

    // console.log("this.groupStructures: ", this.groupStructures);
  }

}