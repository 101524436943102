import packageJson  from '../../package.json';

export const environment = {
  production: true,
  domain: 'tri-wall.azeno.ro',
  version: packageJson.version,
  recaptcha: "6LfNLPYhAAAAABs8qHQDWGkr3Mc9p_1pDahMotAO",
  emailConfig: {
    api: "https://email-api.azeno.ro",
    email: "office@tri-wall.ro",
    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkF6ZW5vIiwiaWF0IjoxNTE2MjM5MDIyfQ.8v_AbV5f_vJzIX-cj9IPXH4AdyqxIm6yOJGiAJqO5EQ",
  },  
};
