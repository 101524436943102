import { Component, OnInit } from '@angular/core';
import { TranslateHelper } from 'src/app/shared/translate-helper';

@Component({
  selector: 'app-slide-images',
  templateUrl: './slide-images.component.html',
  styleUrls: ['./slide-images.component.scss']
})
export class SlideImagesComponent implements OnInit {

  images: number[] = [];
  
  constructor(private translateHelper: TranslateHelper) { }

  ngOnInit(): void {
    this.images = this.translateHelper.getListOfNo('about_us.images.', '.title')    
  }
}
