<section [class]="'page-title-area' + (('error.image' | translate).length> 0 ? ' '+('error.image' | translate) :'item-bg1')">
    <div class="container">
        <div class="section-title section-title-color">
            <span *ngIf="('error.quote' | translate).length> 1" [innerHtml]="'error.quote' | translate"></span>
            <span *ngIf="('error.quote' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'error.quote'}}</span>
            <h2 *ngIf="('error.title' | translate).length> 1" [innerHtml]="'error.title' | translate"></h2>
            <h2 *ngIf="('error.title' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'error.title'}}</h2>

            <p *ngIf="('error.paragraph_text' | translate).length> 1" [innerHtml]="'error.paragraph_text' | translate" [style]="('error.paragraph_text' |
                translate).length <66 ? 'margin-bottom: 60px': ''"></p>
                <p *ngIf="('error.paragraph_text' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'error.paragraph_text'}}</p>
            </div>
        </div>
</section>

<section class="error-area ptb-100">
    <div class="container">
        <div class="error-content">
            <img src="assets/img/error-404.png" alt="error">
            <!-- <form class="search-form">
            <input type="text" class="search-field" placeholder="search">
            <button type="submit" class="search-submit">Search</button>
        </form> -->
        </div>
    </div>
</section>

<app-cta-two id="contact"></app-cta-two>