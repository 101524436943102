import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-portfolio-details-page',
  templateUrl: './portfolio-details-page.component.html',
  styleUrls: ['./portfolio-details-page.component.scss']
})
export class PortfolioDetailsPageComponent implements OnInit {

  portfolioId: number | undefined = undefined;
  
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.portfolioId = Number(routeParams.get('portfolioId'));  
  }

}
