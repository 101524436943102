<section class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span [innerHtml]="'home.partner.quote' | translate"></span>
            <h2 [innerHtml]="'home.partner.title' | translate"></h2>
            <p [innerHtml]="'home.partner.paragraph_text' | translate"></p>            
        </div>
        <div class="partner-slides owl-carousel owl-theme">
            <div class="partner-item" *ngFor="let client of clients">
                <a [href]="'home.partner.details.'+client+'.link' | translate" target="_blank">
                    <img [src]="'assets/img/partner'+client+'.png'" [alt]="'home.partner.details.'+client+'.name' | translate">
                </a>
            </div>

        </div>
    </div>
</section>