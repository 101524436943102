<section class="portfolio-area ptb-100">
    <div class="container">
        <!-- <div class="shorting-menu">
            <button class="filter" *ngFor="let category of cartegories;" [attr.data-filter]="(category == 1 ? '': '.')+('portfolio.categories.'+category+'.name'
                | translate).toLowerCase()">{{'portfolio.categories.'+category+'.name' | translate}}</button>
        </div> -->
        <div class="shorting">
            <div class="row">
                <div *ngFor="let project of projects;" class="col-lg-4 col-md-6">
                    <div class="single-work">
                        <a [routerLink]="'/portfolio/'+project" style="pointer-events: none; cursor: default;">
                            <div class="work-image">
                                <img [src]="'assets/img/work-img'+project+'.jpg'" [alt]="'portfolio.details.'+project+'.title' | translate">
                                <div class="work-overlay">
                                    <h3>{{'portfolio.details.'+project+'.title' | translate}}</h3>
                                    <span class="ps-2" [innerHtml]="'portfolio.details.'+project+'.short_description' | translate"></span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>