<section class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="partner-item" *ngFor="let image of images">
                <!-- TODO image on new tab (rotate) -->
                <a [href]="'assets/img/about-img'+image+'.jpg'" class="popup-btn">
                    <img [src]="'assets/img/about-img'+image+'.jpg'" alt="work-image" [text]="'about_us.images.'+image+'.title' | translate">
                </a>                
            </div>
        </div>
    </div>
</section>