<section id="about" class="about-area ptb-100 bg-image">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row about-image">
                    <div class="image">
                        <img src="assets/img/about-us-img1.png" alt="about">
                    </div>

                    <!-- <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/about-img1.jpg" alt="about">
                        </div>
                    </div> -->

                    <!-- <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/about-img2.jpg" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/about-img3.jpg" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/about-img4.jpg" alt="about">
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content blog-details" [innerHtml]="'about_us.description' | translate">                   
                </div>
            </div>
        </div>
    </div>
</section>