import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-services-details-page',
  templateUrl: './services-details-page.component.html',
  styleUrls: ['./services-details-page.component.scss']
})
export class ServicesDetailsPageComponent implements OnInit {

  serviceId: number | undefined = undefined;
  
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.serviceId = Number(routeParams.get('serviceId'));      
  }

}
