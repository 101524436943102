import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShopPageComponent } from './pages/shop-page/shop-page.component';
import { PortfolioPageComponent } from './pages/portfolio-page/portfolio-page.component';
import { TermsConditionsPageComponent } from './pages/terms-conditions-page/terms-conditions-page.component';
import { CookiePolicyPageComponent } from './pages/cookie-policy-page/cookie-policy-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AboutUsPageComponent } from './pages/about-us-page/about-us-page.component';
import { ServicesPageComponent } from './pages/services-page/services-page.component';
import { PortfolioDetailsPageComponent } from './pages/portfolio-details-page/portfolio-details-page.component';
import { ServicesDetailsPageComponent } from './pages/services-details-page/services-details-page.component';
import { CareerPageComponent } from './pages/career-page/career-page.component';
import { CareerFormPageComponent } from './pages/career-form-page/career-form-page.component';

const routes: Routes = [
    {
        path: '',
        component: HomePageComponent,
    },
    {
        path: 'about-us',
        component: AboutUsPageComponent,
    },      
    {
        path: 'contact',
        component: ContactPageComponent,
    },    
    {
        path: 'shop',
        component: ShopPageComponent,
    },   
    {
        path: 'portfolio',
        component: PortfolioPageComponent,
    },     
    // { 
    //     path: 'portfolio/:portfolioId', 
    //     component: PortfolioDetailsPageComponent 
    // },    
    {
        path: 'services',
        component: ServicesPageComponent,        
    },               
    // {
    //     path: 'services/:serviceId',
    //     component: ServicesDetailsPageComponent,
    // },   
    {
        path: 'career',
        component: CareerPageComponent,        
    },          
    {
        path: 'career-form',
        component: CareerFormPageComponent,
    },               
    {
        path: 'terms-conditions',
        component: TermsConditionsPageComponent,
    },                
    {
        path: 'cookie-policy',
        component: CookiePolicyPageComponent,
    },                
    { 
        path: '**', 
        component: ErrorPageComponent 
    }    
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }