<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/img/logo.png" alt="logo">
            <!-- <img src="assets/img/logo2.png" alt="logo"> -->
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
            aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav navbar-light ms-auto">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">{{'menu.home' | translate}}</span></li>
                <li class="d-none d-lg-block">|</li>
                <li class="nav-item"><a routerLink="about-us" class="nav-link">{{'menu.about' | translate}}</a></li>
                <li class="d-none d-lg-block">|</li>
                <li class="nav-item"><a routerLink="services" class="nav-link">{{'menu.services' | translate}}</a></li>
                <li class="d-none d-lg-block">|</li>
                <li class="nav-item"><a routerLink="portfolio" class="nav-link">{{'menu.portfolio' | translate}}</a></li>
                <li class="d-none d-lg-block">|</li>
                <li class="nav-item"><a [routerLink]="'menu.shop.1.link' | translate" class="nav-link">{{'menu.shop.1.title' | translate}}</a></li>
                <li class="d-none d-lg-block">|</li>
                <li class="nav-item"><a [href]="'menu.shop.2.link' | translate" target="_blank" class="nav-link">{{'menu.shop.2.title' | translate}}</a></li>                
                <li class="d-none d-lg-block">|</li>                
                <li class="nav-item"><a routerLink="contact" class="nav-link pt-1"><i class="icofont-email" style="font-size: 20px;"></i></a></li>
                <li class="d-none d-lg-block">|</li>
                <li class="nav-item dropdown">
                    <a class="emoji-font dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ ('menu.language.'+selectedLanguageCode | translate).substring(0, 5) + selectedLanguageCode.toUpperCase()}}
                    </a>
                    <ul class="dropdown-menu">
                        <li *ngFor="let lang of languages">
                            <button class="emoji-font dropdown-item" type="button" (click)="changeLanguage(lang)">{{lang.text}}</button>
                        </li>
                    </ul>                
                </li>

            </ul>
        </div>
    </div>
</nav>