<section class="portfolio-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span [innerHtml]="'portfolio.quote' | translate"></span>
            <h2 [innerHtml]="'portfolio.title' | translate"></h2>
            <p [innerHtml]="'portfolio.paragraph_text' | translate"></p>
        </div>
        <div class="shorting">
            <div class="row">

                <div class="col-lg-4 col-md-6" *ngFor="let project of projects; let i = index;">
                    <div class="single-work">
                        <a [routerLink]="'/portfolio/'+project" style="pointer-events: none; cursor: default;">
                            <div class="work-image">
                                <img [src]="'assets/img/work-img'+project+'.jpg'" [alt]="'portfolio.details.'+project+'.title' | translate">
                                <div class="work-overlay">
                                    <h3>{{'portfolio.details.'+project+'.title' | translate}}</h3>
                                    <span class="ps-2" style="text-align: right" [innerHtml]="'portfolio.details.'+project+'.short_description' | translate"></span>
                                </div>
                            </div>
                        </a>

                    </div>
                </div>                
            </div> 

            <div class="row">
                <a routerLink="/portfolio" class="btn btn-primary div-center" style="width: auto;" [innerText]="'portfolio.button_more' | translate"></a>
            </div>
        </div>
    </div>
</section>