<section class="why-we-different ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span [innerHtml]="'home.about.quote' | translate"></span>
            <h2 [innerHtml]="'home.about.title' | translate"></h2>
            <p [innerHtml]="'home.about.paragraph_text' | translate"></p>
        </div>
        <div class="tab">
            <ul class="tabs">
                <li *ngFor="let tab of tabs">
                    <a href="#" [innerHtml]="'home.about.details.'+(tab.no)+'.title' | translate"></a>
                </li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item" *ngFor="let tab of tabs">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <h3 [innerHtml]="'home.about.details.'+(tab.no)+'.subtitle' | translate"></h3>
                                <p [innerHtml]="'home.about.details.'+(tab.no)+'.desciption' | translate"></p>
                                <ul>

                                    <div class="counter-wrap tabs_item_content wrap-margin" *ngFor="let point of tab.points">
                                        <div class="single-counter">
                                            <h2>
                                                <div class="icon">
                                                    <i class="{{'home.about.details.'+tab.no+'.points.'+point+'.icon' | translate}}"></i>
                                                </div>
                                            </h2>
                                            <h6 class="font-bold">{{'home.about.details.'+tab.no+'.points.'+point+'.title' | translate}}</h6>
                                            <p [innerHtml]="'home.about.details.'+tab.no+'.points.'+point+'.subtitle' | translate"></p>
                                        </div>
                                    </div>
                                    
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="{{'assets/img/why-we-different'+tab.no+'.jpg'}}" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>

        </div>
    </div>
</section>