import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateHelper } from 'src/app/shared/translate-helper';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomePageComponent implements OnInit {
  
  banners: any[] = [];

  constructor(private translateHelper: TranslateHelper) { }

  ngOnInit(): void {
    this.banners = this.translateHelper.getListOfNo('home.banners.', '.title');
  }  

}
